module.exports = {
  siteTitle: 'Global Foodservice Institute', // Site title.
  siteTitleAlt: 'Restaurant Business Foodservice Certifications', // Alternative site title for SEO.
  siteLogo: '', // Logo used for SEO and manifest.
  siteUrl: 'https://globalfoodserviceinstitute.org', // Domain of your website without pathPrefix.
  pathPrefix: '', // Prefixes all links. For cases when deployed to example.github.io/gatsby-starter-business/.
  siteDescription: 'Provide professional certifications demonstrating individuals have the knowledge to apply on the job and provide value to employers and clients', // Website description used for RSS feeds/meta description tag.
  siteRss: '/rss.xml',
  siteFBAppID: '', // FB Application ID for using app insights
  googleTagManagerID: '', // GTM tracking ID.
  disqusShortname: 'global-foodservice-institute', // Disqus shortname.
  userName: '',
  userTwitter: '',
  userLocation: 'Denver, CO',
  userDescription: '',
  copyright: 'Copyright © 2020. All Rights Reserved.', // Copyright string for the footer of the website and RSS feed.
  themeColor: '#1e1c8a', // Used for setting manifest and progress theme colors.
  backgroundColor: '#ffffff', // Used for setting manifest background color.
}
