import React from 'react';
import { Link, graphql, StaticQuery } from 'gatsby';
import GFI_Institute from '../../assets/img/GFI_Institute-removebg.png';

const NavBar = ({ toggleNavbar, isActive }) => (
	<StaticQuery
		query={graphql`
			query SearchIndexQuery {
				siteSearchIndex {
					index
				}
			}
		`}
		render={(data) => (
			<nav className='navbar is-fixed-top' aria-label='main navigation'>
				<div className='navbar-brand'>
					<Link to='/' className='navbar-item' style={{ fontSize: '2rem' }}>
						<img
							width='112'
							height='28'
							alt='global foodservice institute logo'
							src={GFI_Institute}
							style={{ maxHeight: 'none' }}
						/>
					</Link>
					<button
						className={`button navbar-burger ${isActive ? 'is-active' : ''}`}
						data-target='navMenu'
						onClick={toggleNavbar}
					>
						<span />
						<span />
						<span />
					</button>
				</div>
				<div
					className={`navbar-menu ${isActive ? 'is-active' : ''}`}
					id='navMenu'
				>
					<div className='navbar-end'>
						<Link className='navbar-item' to='/about'>
							About
						</Link>
						<a
							className='navbar-item'
							target='_blank'
							rel='noopener noreferrer'
							href='https://drive.google.com/file/d/1BOIXRXcEfZKn5i2X45x78b3Ooa7HeaQ2/view?usp=sharing'
						>
							Pricing
						</a>
						<Link className='navbar-item' to='/blog'>
							Partners
						</Link>
						<div className='navbar-item'>
							<div className='field is-grouped'>
								<p className='control'>
									<Link className='button is-primary is-outlined' to='/contact'>
										Contact Us
									</Link>
								</p>
							</div>
						</div>
					</div>
				</div>
			</nav>
		)}
	/>
);

export default NavBar;
